import { useLocation,Navigate } from "react-router-dom"
import { useSearchParams } from "react-router-dom";
import axios from "axios";
const { REACT_APP_TROUBADISK_BACKEND_DEV_URL, REACT_APP_TROUBADISK_DOMAIN, REACT_APP_TROUBADISK_SERVICE } = process.env;

// Check if REACT_APP_TROUBADISK_BACKEND_DEV_URL is None
function get_url() {
    if (REACT_APP_TROUBADISK_BACKEND_DEV_URL === undefined) {
      console.log("REACT_APP_TROUBADISK_BACKEND_DEV_URL is undefined, using default")
      return "https://" + REACT_APP_TROUBADISK_SERVICE + "." + REACT_APP_TROUBADISK_DOMAIN + "/api";
    }
    else {
      console.log("REACT_APP_TROUBADISK_BACKEND_DEV_URL is defined, using it")
      return REACT_APP_TROUBADISK_BACKEND_DEV_URL;
    }
  }
  // TODO: Move /api to .env as well
export const baseURL = get_url();
console.log("baseURL: " + baseURL)
export const api = axios.create({
  baseURL: baseURL,
});

const token = sessionStorage.getItem("access_token");
if (token) {
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export const setToken = (token)=>{

    sessionStorage.setItem('access_token', token)// make up your own token
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export const fetchToken = (token)=>{

    return sessionStorage.getItem('access_token')
}

export function RequireToken({children}){
    const [searchParams, setSearchParams] = useSearchParams();
    let audio_id = searchParams.get("audio_id")
    let auth = fetchToken()
    let location = useLocation()

    if(!auth){

        return <Navigate to={'/?audio_id='+ audio_id} state ={{from : location}}/>;
    }

    return children;
}
